import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import arrowIcon from "../../assets/icons/arrowRightBlue.svg";
import { conditionalClassNames } from "../../utils/conditionalClassNames";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from "../../assets/icons/infoIcon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { calculatePrices, Sizes } from "../../utils/calculatePrices";
import { ROUTE_PATHS } from "../../const/routes";

interface IProps {
  sizes: Sizes;
  additionalInfo: string;
}

export const PARCEL_SIZES_CONFIG = [
  {
    id: 1,
    name: "Documents",
    description: "",
    value: "Documents",
  },
  {
    id: 2,
    name: "S",
    description: "",
    value: "S",
  },
  {
    id: 3,
    name: "M",
    description: "up to 5 kg",
    value: "M",
  },
  {
    id: 4,
    name: "L",
    description: "up to 20 kg",
    value: "L",
  },
];
export const getNameBySize = (size: string) => {
  const currentItem = PARCEL_SIZES_CONFIG.find(
    (item) => item?.value?.toLowerCase() === size?.toLowerCase()
  );
  return currentItem
    ? `${currentItem?.name} ${
        currentItem?.description && `(${currentItem?.description})`
      }`
    : null;
};
const TripDetails: React.FC<IProps> = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const resultSizes = calculatePrices(props.sizes);
  const { t } = useTranslation();
  const onPossibleEarningsClick = () => {
    navigate(
      `${ROUTE_PATHS.POSSIBLE_EARNINGS}?hashed-tg-id=${searchParams.get(
        "hashed-tg-id"
      )}`
    );
  };

  const handleVisible = () => setIsVisible((prevState) => !prevState);
  const sizeOrder = ["Documents", "S", "M", "L"];

  return (
    <>
      <div
        onClick={handleVisible}
        className={conditionalClassNames(
          styles.control,
          isVisible && styles.isVisible
        )}
      >
        <p>{t("trip_details.trip_details")}</p>
        <img src={arrowIcon} alt="arrowIcon" />
      </div>

      {isVisible && (
        <div className={styles.wrapper}>
          <p className={styles.title}>{t("trip_details.size")}</p>
          <div className={styles.bioBlock}>
            {props?.sizes && (
              <div className={styles.size}>
                {Object.entries(props.sizes)
                  ?.sort(
                    (a, b) => sizeOrder.indexOf(a[0]) - sizeOrder.indexOf(b[0])
                  )
                  .map((size) => {
                    const price = resultSizes.resultArray.find(
                      (item) => item.size == size[0]
                    );
                    const label = getNameBySize(size[0]);
                    return (
                      label && (
                        <div className={styles.row}>
                          <p className={styles.sizeText}>{label}</p>
                          <p className={styles.price}>{price?.price}$</p>
                        </div>
                      )
                    );
                  })}
              </div>
            )}
            <div className={styles.sizeWidgetBottom}>
              <p className={styles.widgetText}>
                {t("trip_details.possible_earnings")}
                <InfoIcon onClick={onPossibleEarningsClick} />
              </p>
              <p className={styles.totalPrice}>≈ {resultSizes.total}$</p>
            </div>
          </div>
          {props.additionalInfo && (
            <div className={styles.info}>
              <p className={styles.title}>
                {t("trip_details.additional_info")}
              </p>
              <span className={styles.infoText}>{props.additionalInfo}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default TripDetails;
