import React, { FC, useEffect, useState } from "react";

import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { ReactComponent as AgreementIcon } from "../../assets/icons/agreement.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/support.svg";
import { ReactComponent as FriendsIcon } from "../../assets/icons/friends.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrowRight.svg";

import Navigation from "../../components/Navigation/Navigation";
import Avatar from "../../components/Avatar/Avatar";

import styles from "./profilePage.module.css";
import Reviews from "../../components/Reviews/Reviews";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useProfile } from "../../context/ProfileContext";

import { useTranslation } from "react-i18next";
import {
  getHashedTgIdFromLocalStorage,
  getProfileFromLocalStorage,
} from "../../utils/storeActions";
import { removeProtocol } from "../../utils/addProtocol";
import globalRequestStore from "../../store";
import { conditionalClassNames } from "../../utils/conditionalClassNames";
import i18n from "../../i18n";
import { SessionStorageService } from "../../utils/SessionStorageService";
import { axiosInstance } from "../../service/axiosConfig/axiosConfig";

// @ts-ignore
const tg = window.Telegram.WebApp;
const ProfilePage: FC = () => {
  const tgId = getProfileFromLocalStorage();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { profileData, handleGetProfileData } = useProfile();
  const [telegramUserId, setTelegramUserId] = useState(null);

  let currentTgId;
  const localStoreTgId = getHashedTgIdFromLocalStorage();
  currentTgId = searchParams.get("hashed-tg-id") as string;
  if (localStoreTgId && localStoreTgId !== "null") {
    currentTgId = localStoreTgId;
  }

  function sendSupportRequest() {
    globalRequestStore.startLoading();
    fetch(axiosInstance.defaults.baseURL + "/api/users/support/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "tg-id": currentTgId || tgId,
      },
    })
      .then(() => {
        globalRequestStore.finishLoading();
        tg.close();
      })
      .catch((error) => globalRequestStore.setError(error.message));
  }

  function sendAgreementRequest() {
    switch (i18n.language.toUpperCase()) {
      case "RU":
        window.open("https://telegra.ph/FAQs-10-30-3", "_blank");

        break;
      case "EN":
        window.open("https://telegra.ph/FAQs-10-30-2", "_blank");
        break;

      case "UK":
        window.open("https://telegra.ph/FAQs-10-30-4", "_blank");
        break;
    }
    // window.open('https://telegra.ph/Privila-10-17', '_blank');
    // globalRequestStore.startLoading();
    // fetch('https://admin.delivermypack.online/api/users/agreement/', {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'tg-id': currentTgId,
    //   },
    // })
    //   .then(() => {
    //     globalRequestStore.finishLoading();
    //     tg.close();
    //   })
    //   .catch((error) => globalRequestStore.setError(error.message));
  }

  const shareData = {
    url:
      "https://t.me/DeliverMyPackBot?start=ref" +
      SessionStorageService.get("tgId"),
  };

  async function shareWithFriends() {
    try {
      await navigator.share(shareData);
    } catch (err) {
      navigator.clipboard.writeText(shareData.url);
    }
  }

  const onEditClick = async () => {
    navigate(
      `/profile/edit?tg-id=${searchParams.get(
        "tg-id"
      )}&hashed-tg-id=${searchParams.get("hashed-tg-id")}`
    );
  };

  useEffect(() => {
    if (searchParams) {
      let currentTgId;
      const localStoreTgId = getHashedTgIdFromLocalStorage();

      if (localStoreTgId && localStoreTgId !== "null") {
        currentTgId = localStoreTgId;
      } else if (localStoreTgId) {
        currentTgId = searchParams.get("hashed-tg-id");
      }

      handleGetProfileData(currentTgId);
    }
  }, [searchParams]);

  useEffect(() => {
    // Проверяем, доступен ли объект Telegram Web App
    // @ts-ignore
    const tg = window?.Telegram?.WebApp;
    if (tg) {
      const user = tg.initDataUnsafe?.user;
      if (user) {
        setTelegramUserId(user.id); // Получаем Telegram ID пользователя
      } else {
        // console.error('Пользователь не авторизован');
      }
    } else {
      // console.error('Telegram WebApp не инициализирован');
    }
  }, []);

  return (
    <div className={styles.pageWrapper}>
      {/*<Loader/>*/}
      <div className={styles.content}>
        <div className={styles.profileAvatarWrapper}>
          <Avatar
            profileName={profileData ? profileData.name : "P"}
            avatarUrl={profileData?.photo_link}
          />
          <div className={styles.topInfoWrapper}>
            <p className={styles.profileName}>
              {profileData ? profileData.name : "-"}
            </p>
            {profileData && profileData?.rating && (
              <p className={styles.rating}>
                <StarIcon />
                <span>{profileData?.rating}</span>
              </p>
            )}
          </div>
          <button onClick={onEditClick} className={styles.edit}>
            {t("profile_page.edit_button")}
          </button>
        </div>
        <div className={styles.bioBlock}>
          {/*{profileData?.bio && (*/}
          <div className={styles.bio}>
            <p>{t("profile_page.bio")}</p>
            <div className={styles.bioDescription}>
              {profileData?.bio ? profileData.bio : "-"}
            </div>
          </div>
          {/*)}*/}
          {/*{profileData?.social_link && (*/}
          <div
            className={conditionalClassNames(styles.links, styles.linksAndBio)}
          >
            <p>{t("profile_page.links")}</p>
            {profileData?.social_link ? (
              <a
                target={"_blank"}
                href={profileData ? profileData.social_link : "#"}
              >
                {removeProtocol(profileData.social_link)}
              </a>
            ) : (
              "-"
            )}
          </div>
          {/*)}*/}
        </div>
        <div className={styles.optionalBlock}>
          <div className={styles.optionItem}>
            <FriendsIcon />
            <div className={styles.optionItemBlock} onClick={shareWithFriends}>
              <p>{t("profile_page.invite_friends")}</p>
              <RightArrow />
            </div>
          </div>
          <div className={styles.optionItem}>
            <SupportIcon />
            <div
              className={styles.optionItemBlock}
              onClick={sendSupportRequest}
            >
              <p>{t("profile_page.support")}</p>
              <RightArrow />
            </div>
          </div>
          <div className={styles.optionItem}>
            <AgreementIcon />
            <div
              className={styles.optionItemBlock}
              onClick={sendAgreementRequest}
            >
              <p>{t("profile_page.user_agreement")}</p>
              <RightArrow />
            </div>
          </div>
        </div>
      </div>
      <Reviews />
      <Navigation />
    </div>
  );
};

export default ProfilePage;
