import React, { FC, useEffect, useRef, useState } from "react";

import { InputWrapper } from "../../components/Input/Input";
import { Button } from "../../components/Button/Button";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import Arrows from "../../assets/icons/arrows.svg";

import style from "./editProfile.module.css";
import { BASE_URL, useProfile } from "../../context/ProfileContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addProtocol, removeProtocol } from "../../utils/addProtocol";
import CustomDatePicker from "../../components/CustomDatePicker";
import moment from "moment";
import Dialog from "../../components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import {
  getHashedTgIdFromLocalStorage,
  getProfileFromLocalStorage,
} from "../../utils/storeActions";
import globalRequestStore from "../../store";
import { updateUserProfile } from "../../service/user/parcel";
import { conditionalClassNames } from "../../utils/conditionalClassNames";
import { validateName } from "../../utils/validation";
import { ROUTE_PATHS } from "../../const/routes";
import TextArea from "../../components/TextArea";
import styles from "../AdditionalInfoPage/styles.module.css";

///Bug here
///add translator

const formatDateForPicker = (dateString: string): string => {
  const [year, month, day] = dateString.split("-");
  return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
};

function capitalizeFirstLetter(str?: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const EditProfile: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tgId = getHashedTgIdFromLocalStorage();
  const { t } = useTranslation();

  const option: { title: string; value: string }[] = [
    { title: t("edit_profile_page.gender_male"), value: "male" },
    { title: t("edit_profile_page.gender_female"), value: "female" },
    {
      title: t("edit_profile_page.gender_not_specified"),
      value: "not_specified",
    },
  ];

  const { profileData, handleGetProfileData } = useProfile();

  const [loading, setLoading] = useState(false);
  const [editProfile, setEditProfile] = useState<any>(profileData);
  // console.log(profileData, editProfile, 111)
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    profileData?.sex || t("edit_profile_page.gender_placeholder")
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [keyboardShow, setKeyboardShow] = useState(false);
  const [bottumShow, setBottumShow] = useState(false);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);

  const handlePopupOpen = () => setPopupOpen(true);
  const handlePopupClose = () => setPopupOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditProfile((prevState) => ({
      ...prevState,
      social_link: event.target.value,
    }));
  };

  useEffect(() => {
    if (profileData?.birthdate) {
      const formattedDate = formatDateForPicker(profileData.birthdate);
      setEditProfile((prevState) => ({
        ...prevState,
        birthdate: formattedDate,
      }));
    }
  }, [profileData?.birthdate]);

  useEffect(() => {
    if (tgId) {
      handleGetProfileData(
        tgId || (searchParams.get("hashed-tg-id") as string)
      );
    }
  }, [searchParams]);

  const handleDateChange = (date: string) => {
    // console.log(date, 'date')
    // console.log(prevState,'prevStates')
    setEditProfile((prevState) => {
      return { ...prevState, birthdate: moment(date).format("YYYY-MM-DD") };
    });
    console.error(editProfile?.birthdate);
  };

  const handleProfileUpdate = async () => {
    globalRequestStore.startLoading();
    if (!validateName(editProfile?.name)) {
      globalRequestStore.setError(t("error_label.name") + editProfile?.name);
      return;
    }
    const localStoreTgId = getHashedTgIdFromLocalStorage();
    if (editProfile) {
      updateUserProfile(profileData?.id, editProfile)
        .then((response) => {
          if (response?.error) {
            throw new Error(t("error_label.link"));
          }
          handleGetProfileData(
            localStoreTgId || (searchParams.get("hashed-tg-id") as string)
          );
          if (response) {
            navigate(
              `/profile?hashed-tg-id=${
                localStoreTgId || searchParams.get("hashed-tg-id")
              }`
            );
          }
        })
        .catch((err) => {
          globalRequestStore.setError(t("error_label.link"));
        })
        .finally(() => {
          globalRequestStore.finishLoading();
        });
    }
  };

  const handleProfileDelete = async () => {
    globalRequestStore.startLoading();
    if (searchParams.get("hashed-tg-id")) {
      try {
        const res = await fetch(`${BASE_URL}/users/${profileData?.id}/`, {
          method: "DELETE",
          headers: {
            "tg-id": tgId || (searchParams.get("hashed-tg-id") as string),
          },
        });
        if (res.ok) {
          const data = await res.json().catch(() => null);
          navigate(
            `/?tg-id=${profileData?.tg_id}&hashed-tg-id=${
              tgId || searchParams.get("hashed-tg-id")
            }`
          );
        } else {
          handleModalClose();
          handlePopupOpen();
          setTimeout(() => {
            handlePopupClose();
          }, 3000);
        }

        globalRequestStore.finishLoading();
      } catch (err: any) {
        globalRequestStore.setError(t("error_label.delete"));
        // globalRequestStore.setError('You cant delete account until you have open requests.')
        // console.warn(err.message);
        // handleModalClose();
        // handlePopupOpen();
        // setTimeout(() => {
        //   handlePopupClose();
        // }, 3000);
        globalRequestStore.finishLoading();
      }

      await handleGetProfileData(
        tgId || (searchParams.get("hashed-tg-id") as string)
      );
    }
  };
  const handleFocus = (ref: React.RefObject<HTMLElement>) => {
    // if (ref.current) {
    //   setTimeout(() => {
    //     if (ref.current) {
    //       window.scrollBy({
    //         top: window.innerHeight,
    //         behavior: 'smooth',
    //       });
    //     }
    //   }, 1000);
    // }
  };
  document.querySelectorAll("#needScroll").forEach((el) => {
    el.addEventListener("focus", () => {
      window.scrollTo(0, document.body.scrollHeight);
      setKeyboardShow(true);
    });

    el.addEventListener("blur", () => {
      setKeyboardShow(false);
    });
  });

  const handleChangeName = (name) => {
    setEditProfile((prevState) => ({
      ...prevState,
      name: name.substring(0, 100),
    }));
  };
  const handleBioChange = (value) => {
    setEditProfile((prevState) => ({
      ...prevState,
      bio: value.substring(0, 500),
    }));
  };
  useEffect(() => {
    document.querySelectorAll("#needAdd").forEach((el) => {
      el.addEventListener("focus", () => {
        window.scrollTo(0, document.body.scrollHeight);
        setBottumShow(true);
        // alert(5)
      });

      el.addEventListener("blur", () => {
        setBottumShow(false);
      });
    });
  }, []);
  return (
    <div>
      <div
        className={conditionalClassNames(
          style.mainWrapper,
          keyboardShow && style.mainWrapperWithKeyboard,
          bottumShow && style.bottomSpace
        )}
      >
        <div className={style.wrapper_input}>
          <p className={style.label}>{t("edit_profile_page.about_you")}</p>

          <InputWrapper
            id="needAdd"
            type="text"
            placeholder={t("edit_profile_page.name_placeholder")}
            value={editProfile?.name || ""}
            onChange={handleChangeName}
          />

          <div className={style.blockGender} onClick={() => setIsOpen(!isOpen)}>
            <span
              className={
                selectedOption === "Gender"
                  ? style.defaultGender
                  : style.selectedGender
              }
            >
              {option.find((item) => item.value === selectedOption)?.title ||
                t("edit_profile_page.gender_placeholder")}
            </span>

            <img src={Arrows} alt="" />

            {isOpen && (
              <div className={style.option}>
                {option.map((item, key) => (
                  <div
                    key={key}
                    className={style.optionItem}
                    onClick={() => {
                      setSelectedOption(item.value);
                      setEditProfile((prevState) => ({
                        ...prevState,
                        sex: item.value,
                      }));
                    }}
                  >
                    <span className={style.optionText}>{item.title}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <CustomDatePicker
            initialDate={editProfile?.birthdate || undefined}
            onDateChange={handleDateChange}
          />
          <p className={style.label}>{t("edit_profile_page.bio")}</p>
          {/*<textarea*/}
          {/*  id="#needScroll"*/}
          {/*  rows={String(editProfile?.bio).length / 50} // Початкова кількість рядків*/}
          {/*  style={{*/}
          {/*    width: '100%',*/}
          {/*    overflow: 'hidden',*/}
          {/*    resize: 'none',*/}
          {/*  }}*/}
          {/*  onInput={(e: any) => {*/}
          {/*    e.target.style.height = 'auto';*/}
          {/*    e.target.style.height = `${e.target.scrollHeight}px`;*/}
          {/*  }}*/}
          {/*  ref={bioRef}*/}
          {/*  onFocus={() => handleFocus(bioRef)}*/}
          {/*  value={editProfile?.bio || ''}*/}
          {/*  onChange={(event: any) => {*/}
          {/*    event.target.style.height = 'auto';*/}
          {/*    event.target.style.height = `${event.target.scrollHeight}px`;*/}

          {/*  }*/}
          {/*  }*/}
          {/*  className={style.textArea}*/}
          {/*  placeholder={t('edit_profile_page.bio_placeholder')}*/}
          {/*></textarea>*/}
          <TextArea
            id="needScroll"
            value={editProfile?.bio || ""}
            onChange={handleBioChange}
            className={styles.textArea}
            placeholder={t("edit_profile_page.bio_placeholder")}
          />

          <p className={style.inputValue}>{editProfile?.bio?.length} / 500</p>

          <input
            id="needScroll"
            ref={instagramRef}
            onFocus={() => handleFocus(instagramRef)}
            className={style.input}
            type="text"
            value={removeProtocol(editProfile?.social_link) || ""}
            onChange={handleInputChange}
            placeholder="instagram.com"
          />
        </div>
        <button onClick={handleModalOpen} className={style.deleteButton}>
          {t("edit_profile_page.delete_account_button")}
        </button>
        <div className={style.button} style={{ marginTop: 10 }}>
          <Button
            text={t("edit_profile_page.done_button")}
            color="fill"
            size="large"
            onClick={handleProfileUpdate}
            disabled={loading}
          />
        </div>
      </div>
      {/*<footer className={style.footer}>*/}
      {/*  <div className="container">*/}
      {/*    <div className={style.button}>*/}
      {/*      <Button*/}
      {/*        text={t('edit_profile_page.done_button')}*/}
      {/*        color="fill"*/}
      {/*        size="large"*/}
      {/*        onClick={handleProfileUpdate}*/}
      {/*        disabled={loading}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    */}
      {/*  </div>*/}
      {/*</footer>*/}
      {modalOpen && (
        <Dialog
          title={t("edit_profile_page.delete_account_button")}
          onCancel={handleModalClose}
          onSubmit={handleProfileDelete}
          additionalText={t("edit_profile_page.delete_account_confirmation")}
        />
      )}
      {popupOpen && (
        <div className={style.deletePopup}>
          <CloseIcon />
          <p>{t("edit_profile_page.delete_account_error")}</p>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
