import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageAboutYou } from "./pages/PageAboutYou/PageAboutYou";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import EditProfile from "./pages/EditProfile/EditProfile";
import "./App.css";
import "./i18n";

import { AdditionalInfoPage } from "./pages/AdditionalInfoPage";
import ParcelSendPage from "./pages/ParcelSendPage/ParcelSendPage";
import DateRangeSelect from "./pages/DateRangeSelect/DateRangeSelect";
import LocationSelect from "./pages/LocationSelect/LocationSelect";
import ParcelSizeSelect from "./pages/ParcelSizeSelect/ParcelSizeSelect";
import ParcelSendSearchResultsPage from "./pages/ParcelSendSearchResultsPage/ParcelSendSearchResultsPage";
import TrackingPage from "./pages/TrackingPage";
import ArchivedTrackingPage from "./pages/ArchivedTrackingPage";
import SenderDeliverDetailPage from "./pages/SenderDeliveryDetailPage";
import ParcelDeliverPage from "./pages/ParcelDeliverPage/ParcelDeliverPage";
import DeliverParcelSizeSelect from "./pages/DeliverParcelSizeSelect/DeliverParcelSizeSelect";
import DeliverProvideMoreInfoPage from "./pages/DeliverProvideMoreInfoPage/DeliverProvideMoreInfoPage";
import CheckYourDeliveryPage from "./pages/CheckYourDeliveryPage/CheckYourDeliveryPage";
import UserInfo from "./pages/UserInfo";
import GiveFeedBackPage from "./pages/GiveFeedBackPage";
import CourierDeliverDetailPage from "./pages/CourierDeliveryDetailPage";
import EditInfoPage from "./pages/EditInfoPage";
import TrackingSizeSelect from "./pages/TrackingSizeSelect";
import SendParcelCourierPage from "./pages/SendParcelCourierPage/SendParcelCourierPage";

import ParcelSizePriceSelect from "./pages/ParcelSizePriceSelect/ParcelSizePriceSelect";
import PossibleEarningsExplanationPage from "./pages/PossibleEarningsExplanationPage/PossibleEarningsExplanationPage";
import AdditionalInfoDetailPage from "./pages/AdditionalInfoDetailPage";
import TrackingSizePriceSelect from "./pages/TrackingSizePriceSelect";
import SendParcelSingleResultPage from "./pages/SendParcelSingleResultPage/SendParcelSingleResultPage";
import Settings from "./components/Settings";
import globalRequestStore from "./store";
import PathListener from "./components/PathListener";
import { ROUTE_PATHS } from "./const/routes";
import PhotoPage from "./pages/PhotoPage";
import { TermsOfUsePage } from "./pages/TermsOfUsePage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import PlugPage from "./pages/Plug";
import CustomLocationSelect from "./pages/CustomLocationSelect/LocationSelect";
import CustomDateRangeSelect from "./pages/CustomDateRangeSelect/DateRangeSelect";
import i18n from "./i18n";
import { useProfile } from "./context/ProfileContext";
interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

type PathType = (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS];
//@ts-ignore
export const tg = window.Telegram.WebApp;
const routes: RouteConfig[] = [
  { path: ROUTE_PATHS.HOME, element: <PageAboutYou /> },
  { path: ROUTE_PATHS.PLUG, element: <PlugPage /> },
  { path: ROUTE_PATHS.PROFILE, element: <ProfilePage /> },
  { path: ROUTE_PATHS.PHOTO_PAGE, element: <PhotoPage /> },
  { path: ROUTE_PATHS.TERMS_OF_USE_PAGE, element: <TermsOfUsePage /> },
  { path: ROUTE_PATHS.PRIVACY_POLICY_PAGE, element: <PrivacyPolicyPage /> },
  { path: ROUTE_PATHS.USER_INFO, element: <UserInfo /> },
  { path: ROUTE_PATHS.EDIT_PROFILE, element: <EditProfile /> },
  { path: ROUTE_PATHS.ADDITIONAL_INFO, element: <AdditionalInfoPage /> },
  { path: ROUTE_PATHS.PARCEL_SEND, element: <ParcelSendPage /> },
  { path: ROUTE_PATHS.SELECT_DATE_RANGE, element: <DateRangeSelect /> },
  { path: ROUTE_PATHS.DELIVER_PARCEL_DATE_RANGE, element: <DateRangeSelect /> },
  { path: ROUTE_PATHS.SELECT_LOCATION, element: <LocationSelect /> },
  {
    path: ROUTE_PATHS.CUSTOM_SELECT_LOCATION,
    element: <CustomLocationSelect />,
  },
  {
    path: ROUTE_PATHS.CUSTOM_DATE_RANGE_SELECT,
    element: <CustomDateRangeSelect />,
  },
  { path: ROUTE_PATHS.PARCEL_SIZE_SELECT, element: <ParcelSizeSelect /> },
  {
    path: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULTS,
    element: <ParcelSendSearchResultsPage />,
  },
  {
    path: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULT,
    element: <SendParcelSingleResultPage />,
  },
  {
    path: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULT_COURIER,
    element: <SendParcelCourierPage />,
  },
  {
    path: ROUTE_PATHS.TRACKING_SIZE_PRICE_SELECT,
    element: <TrackingSizePriceSelect />,
  },

  { path: ROUTE_PATHS.TRACKING, element: <TrackingPage /> },
  { path: ROUTE_PATHS.TRACKING_ARCHIVED, element: <ArchivedTrackingPage /> },
  {
    path: ROUTE_PATHS.SENDER_DELIVER_DETAIL_PAGE,
    element: <SenderDeliverDetailPage />,
  },
  { path: ROUTE_PATHS.GIVE_FEEDBACK_PAGE, element: <GiveFeedBackPage /> },
  {
    path: ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE,
    element: <CourierDeliverDetailPage />,
  },
  { path: ROUTE_PATHS.EDIT_INFO_PAGE, element: <EditInfoPage /> },
  { path: ROUTE_PATHS.TRACKING_SIZE_SELECT, element: <TrackingSizeSelect /> },
  {
    path: ROUTE_PATHS.ADDITIONAL_INFO_DETAIL_PAGE,
    element: <AdditionalInfoDetailPage />,
  },

  { path: ROUTE_PATHS.PARCEL_DELIVER, element: <ParcelDeliverPage /> },
  { path: ROUTE_PATHS.DELIVER_SELECT_LOCATION, element: <LocationSelect /> },
  {
    path: ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT,
    element: <DeliverParcelSizeSelect />,
  },
  {
    path: ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT,
    element: <ParcelSizePriceSelect />,
  },
  {
    path: ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT_EDIT,
    element: <ParcelSizePriceSelect />,
  },

  {
    path: ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT_EDIT,
    element: <DeliverParcelSizeSelect />,
  },
  {
    path: ROUTE_PATHS.DELIVER_PROVIDE_MORE_INFO,
    element: <DeliverProvideMoreInfoPage />,
  },
  {
    path: ROUTE_PATHS.POSSIBLE_EARNINGS,
    element: <PossibleEarningsExplanationPage />,
  },

  { path: ROUTE_PATHS.CHECK_YOUR_DELIVER, element: <CheckYourDeliveryPage /> },
];

function App() {
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const settingsRef = useRef<HTMLDivElement | null>(null);
  const { profileData } = useProfile();

  const handleOpenSettings = () => setSettingsOpen((prevState) => !prevState);

  const handleClickOutside = (event: MouseEvent) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target as Node)) {
      globalRequestStore.hideSettings();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    tg.ready();
    tg.expand();
    tg.SettingsButton.show();
    tg.SettingsButton.onClick(() => globalRequestStore.showSettings());
    tg.disableVerticalSwipes();

    const scrollContainer = document.querySelector(".scroll-container") as any;

    scrollContainer.addEventListener("wheel", function (e) {
      if (scrollContainer.scrollHeight > scrollContainer.clientHeight) {
        scrollContainer.scrollTop += e.deltaY;
        e.preventDefault();
      }
    });
  }, []);

  if (profileData && profileData.language_code) {
    i18n.changeLanguage(profileData.language_code);
  }
  return (
    <div className="scroll-container">
      {/*TODO for test*/}
      {/*<div className={'buildNumber'}>1.0.2</div>*/}
      {/*TODO for test*/}
      {/*<div onClick={() => globalRequestStore.showSettings()}>open settings</div>*/}

      <BrowserRouter>
        <div ref={settingsRef}>
          <Settings isOpen={settingsOpen} handleOpen={handleOpenSettings} />
        </div>
        <PathListener />
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
