import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../components/Button/Button";
import MainImg from "./../../assets/icons/shippingbox.svg";
import NoticeIcon from "./../../assets/icons/importent.svg";
import style from "./PageAboutYou.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addProtocol } from "../../utils/addProtocol";
import { useTranslation } from "react-i18next";
import moment from "moment";
import globalRequestStore from "../../store";
import { validateName } from "../../utils/validation";
import { ROUTE_PATHS } from "../../const/routes";
import { conditionalClassNames } from "../../utils/conditionalClassNames";
import i18n from "../../i18n";
import { axiosInstance } from "../../service/axiosConfig/axiosConfig";

export const PageAboutYou = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const open_page = searchParams.get("open_page");
  const tgId = searchParams.get("tg-id");
  const hashedTgId = searchParams.get("hashed-tg-id");
  const [inputValue, setInputValue] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [keyboardShow, setKeyboardShow] = useState(false);
  const [spaceShow, setSpaceShow] = useState(false);

  useEffect(() => {
    const checkUserExists = async () => {
      try {
        globalRequestStore.startLoading();
        const response = await fetch(axiosInstance.defaults.baseURL + `/api/users/`, {
          headers: {
            "Content-Type": "application/json",
            "tg-id": hashedTgId || "",
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.language_code) {
            localStorage.setItem("selectedLanguage", data.language_code);
          }
          if (open_page === "deliver_parcel") {
            navigate(`${ROUTE_PATHS.PARCEL_DELIVER}?hashed-tg-id=${hashedTgId}`);
          } else {
            navigate(`/parcel-send?hashed-tg-id=${hashedTgId}`);
          }
        } else {
          navigate(`/?tg-id=${tgId}&hashed-tg-id=${hashedTgId}`);
        }
        globalRequestStore.finishLoading();
      } catch (error: any) {
        globalRequestStore.setError(error.message);
      }
    };
    checkUserExists();
  }, [hashedTgId, navigate]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = async () => {
    if (!validateName(name)) {
      globalRequestStore.setError(t("error_label.name") + name);
      return;
    }

    const formData = {
      name,
      bio: "",
      social_link: addProtocol(inputValue),
      birthdate: null,
      tg_id: tgId,
      hashed_tg_id: hashedTgId,
      language_code: ["uk", "en", "ru"].includes(i18n.language) ? i18n.language : "en",
    };

    try {
      const response = await fetch(axiosInstance.defaults.baseURL + "/api/users/register/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(t("error_label.link"));
      }
      navigate(`${ROUTE_PATHS.PARCEL_SEND}?hashed-tg-id=${hashedTgId}&is_registered=true`);
    } catch (error: any) {
      globalRequestStore.setError(error.message);
    }
  };

  useEffect(() => {
    document.querySelectorAll("#needScroll").forEach((el) => {
      el.addEventListener("focus", () => {
        window.scrollTo(0, document.body.scrollHeight);
        setKeyboardShow(true);
      });

      el.addEventListener("blur", () => {
        setKeyboardShow(false);
      });
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("#space").forEach((el) => {
      el.addEventListener("focus", () => {
        setSpaceShow(true);
      });

      el.addEventListener("blur", () => {
        setSpaceShow(false);
      });
    });
  }, []);

  return (
    <div className="container">
      <div
        className={conditionalClassNames(
          style.mainWrapper,
          keyboardShow && style.mainWrapperWithKeyboard,
          spaceShow && style.space
        )}
      >
        <div className={style.wrapper}>
          <img src={MainImg} alt="" className={style.mainImg} />
          <h1 className={style.title}>{t("about_you_page.title")}</h1>
          <p className={style.text}>{t("about_you_page.subtitle")}</p>
        </div>

        <div className={style.wrapper_input}>
          <p className={style.label}>{t("about_you_page.about_you_label")}</p>

          <input
            id="space"
            className={style.input}
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder={t("about_you_page.name_placeholder")}
          />
          <div className={style.desc}>
            <img src={NoticeIcon} alt="icon" style={{ height: "20px", width: "20px" }} />
            <p>{t("about_you_page.description")}</p>
          </div>
        </div>
        <footer className={style.footer}>
          <div className={style.button}>
            <Button
              text={t("about_you_page.start_button")}
              color="fill"
              size="large"
              onClick={handleSubmit}
              disabled={!name.trim()}
            />
          </div>
        </footer>
      </div>
    </div>
  );
};
