import React, { FC, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { DateRangePicker } from "react-date-range";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { useSendParcel } from "../../context/SendParcelContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import styles from "./DateRangeSelect.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button/Button";
import { useDeliverParcel } from "../../context/DeliverParcelContext";
import { ROUTE_PATHS } from "../../const/routes";

// Register the English locale to fix the "localize" error
registerLocale("en-US", enUS);

const GlobalStyle = createGlobalStyle`
  .rdrDefinedRangesWrapper,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthName {
    text-align: center;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    color: #000000;
    padding: 0;
    margin-bottom: 8px;
  }

  .rdrMonth {
    padding-left: 0;
    padding-right: 0;
  }

  .rdrWeekDays .rdrWeekDay {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
  
  .rdrDateRangePickerWrapper {
      height: calc(100vh - 152.5px);
      overflow-y: auto;
  }

  //.rdrDayToday span {
  //  color: white!important;
  //}
  //
  //.rdrDayToday .rdrDayNumber span {
  //    color: black!important;
  //    font-size: 13px;
  //    font-weight: 400;
  //    line-height: 16px;
  //}
  //
  //.rdrDayToday .rdrDayNumber span::after {
  //    background: #007AFF!important;
  //}

  .rdrStartEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #007AFF!important;
  }

  .rdrInRange {
    color: #DDE8FF!important;
  }

  .rdrDayNumber {
    font-weight: 590;
  }

  .rdrInRange ~ .rdrDayNumber span {
    color: #000000!important;
  }

  .rdrEndEdge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #007AFF!important;
  }

  .rdrDayEndPreview,
  .rdrDayStartPreview{
    border: none;
    color: #007AFF!important;
  }

  .rdrMonths {
    flex-direction: column;
  }
`;

const DateRangeSelect: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isFromSendPage = location?.pathname?.includes(ROUTE_PATHS.PARCEL_SEND);
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { dateRange: sendState, handleDateRangeChange: sendDateRangeChange } =
    useSendParcel();
  const {
    dateRange: deliverState,
    handleDateRangeChange: deliverDateRangeChange,
  } = useDeliverParcel();

  const oldDateRange = isFromSendPage ? sendState : deliverState;
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate:
        (Array.isArray(oldDateRange) && oldDateRange[0]?.startDate) ||
        new Date(),
      endDate:
        (Array.isArray(oldDateRange) && oldDateRange[0]?.endDate) || new Date(),
      key: "selection",
    },
  ]);

  const handleDateRangeChange = (
    data: [{ startDate: Date; endDate: Date; key: string }] | undefined
  ) => {
    setDateRange(data);
  };

  const handleReset = () => {
    setDateRange([
      { startDate: new Date(), endDate: new Date(), key: "selection" },
    ]);
    // location.pathname.includes(ROUTE_PATHS.PARCEL_SEND) ? sendDateRangeChange(undefined) : deliverDateRangeChange(undefined);
  };

  const handleSubmit = () => {
    if (isFromSendPage) {
      // @ts-ignore
      sendDateRangeChange(dateRange);
    } else {
      // @ts-ignore
      deliverDateRangeChange(dateRange);
    }
    navigate(
      `${
        location.pathname.includes(ROUTE_PATHS.PARCEL_SEND)
          ? ROUTE_PATHS.PARCEL_SEND
          : ROUTE_PATHS.PARCEL_DELIVER
      }?tg-id=${searchParams.get("tg-id")}&hashed-tg-id=${searchParams.get(
        "hashed-tg-id"
      )}`
    );
  };

  return (
    <div className={styles.calendarWrapper}>
      <GlobalStyle />
      <DateRangePicker
        minDate={new Date()}
        weekStartsOn={1}
        ranges={dateRange}
        // @ts-ignore
        onChange={(item) => {
          // @ts-ignore
          handleDateRangeChange([item.selection]);
        }}
        months={2}
        direction="vertical"
        locale={enUS}
      />
      <div className={styles.footer}>
        <div>
          {dateRange[0]?.startDate?.toLocaleDateString(
            t("date_range_select.calendar"),
            { day: "2-digit", month: "long" }
          )}{" "}
          -{" "}
          {dateRange[0]?.endDate?.toLocaleDateString(
            t("date_range_select.calendar"),
            { day: "2-digit", month: "long" }
          )}
        </div>

        <div className={styles.buttons}>
          <Button
            text={t("date_range_select.title")}
            size={"large"}
            color={"fill"}
            onClick={handleSubmit}
          />
          <Button
            text={t("date_range_select.reset")}
            size={"large"}
            color={"bezeled"}
            onClick={handleReset}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelect;
