import React, { useEffect, useState } from "react";

import { ReactComponent as SendParcelIcon } from "../../assets/icons/sendParcelIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrowRight.svg";

import styles from "./ParcelSendPage.module.css";
import { DESTINATIONS, useSendParcel } from "../../context/SendParcelContext";
import { formatDate, formatFullDate, transformDate } from "../../utils/dateUtil";
import { Button } from "../../components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import { useTranslation } from "react-i18next";
import { useRecentSearch } from "../../context/RecentSearchContext";
import Avatar, { AvatarSizes } from "../../components/Avatar/Avatar";
import {
  getHashedTgIdFromLocalStorage,
  getProfileFromLocalStorage,
  saveProfileToLocalStorage,
} from "../../utils/storeActions";
import { ProfileInterface } from "../../context/ProfileContext";
import globalRequestStore from "../../store";
import { ROUTE_PATHS } from "../../const/routes";

const ParcelSendPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const localStoreTgId = getHashedTgIdFromLocalStorage();
  const { t } = useTranslation();

  const [recentSearchResults, setRecentSearchResults] = useState<any[]>([]);
  const { handleGetRecentSearch } = useRecentSearch();
  const {
    dateRange,
    parcelSize,
    from,
    to,
    handleDestinationChange,
    handleFromChange,
    handleToChange,
    handleDateRangeChange,
    handleParcelSizeChange,
    handleReset,
  } = useSendParcel();

  const handleFetchRecentResults = async () => {
    const data = await handleGetRecentSearch(localStoreTgId as string, "send");

    if (data) {
      setRecentSearchResults(data);
    }
  };

  const handleNavigateToSearchResultsPageFromRecent = (
    from:
      | string
      | {
          title: string;
          placeId: string;
        },
    to: string | Object,
    parcelSize: string,
    dateRange: { startDate: Date; endDate: Date }
  ) => {
    if (from && to && parcelSize && dateRange) {
      // @ts-ignore
      handleFromChange(from);
      // @ts-ignore
      handleToChange(to);
      handleParcelSizeChange(parcelSize);
    }
  };
  useEffect(() => {}, []);
  const handleNavigateToSearchResultsPage = () => {
    if (from && to && parcelSize && dateRange) {
      navigate(
        `${ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULTS}?tg-id=${searchParams.get("tg-id")}&hashed-tg-id=${
          localStoreTgId || searchParams.get("hashed-tg-id")
        }&from=${from}&to=${to}&parcel_size=${parcelSize}&date_range=${
          formatDate(dateRange[0].startDate) + "-" + formatDate(dateRange[0].endDate)
        }`
      );
    }
  };

  const handleNavigateToSelectLocationPage = () => {
    navigate(
      `${ROUTE_PATHS.SELECT_LOCATION}?tg-id=${searchParams.get("tg-id")}&hashed-tg-id=${
        localStoreTgId || searchParams.get("hashed-tg-id")
      }`,
      { state: { from: "send" } }
    );
  };

  const handleNavigateToSelectDateRangePage = () => {
    navigate(
      `${ROUTE_PATHS.SELECT_DATE_RANGE}?tg-id=${searchParams.get("tg-id")}&hashed-tg-id=${
        localStoreTgId || searchParams.get("hashed-tg-id")
      }`
    );
  };

  const handleNavigateToSelectParcelSizePage = () => {
    navigate(
      `${ROUTE_PATHS.PARCEL_SIZE_SELECT}?tg-id=${searchParams.get("tg-id")}&hashed-tg-id=${
        localStoreTgId || searchParams.get("hashed-tg-id")
      }`
    );
  };

  const onToDestinationClick = () => {
    handleDestinationChange(DESTINATIONS.to);
    handleNavigateToSelectLocationPage();
  };

  const onFromDestinationClick = () => {
    handleDestinationChange(DESTINATIONS.from);
    handleNavigateToSelectLocationPage();
  };

  useEffect(() => {
    if (localStoreTgId) {
      handleFetchRecentResults();
    }
  }, [searchParams]);

  useEffect(() => {
    const tgId = localStoreTgId;
    const profile = getProfileFromLocalStorage() as ProfileInterface;
    if (tgId && tgId !== "null") {
      saveProfileToLocalStorage({
        ...profile,
      });
    }
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.topWidget}>
        <SendParcelIcon />
        <h1 className={styles.title}>{t("parcel_send_page.title")}</h1>
        <p className={styles.subTitle}>{t("parcel_send_page.subtitle")}</p>
      </div>

      <div className={styles.destinationWidget}>
        <div onClick={onFromDestinationClick} className={styles.destination}>
          <span>{t("parcel_send_page.from")} </span>
          {from && <span className={styles.destinationInfo}>{from.title}</span>}
          <ArrowRightIcon />
        </div>
        <div onClick={onToDestinationClick} className={styles.destination}>
          <span>{t("parcel_send_page.to")} </span>
          {to && <span className={styles.destinationInfo}>{to.title}</span>}
          <ArrowRightIcon />
        </div>
      </div>
      <p className={styles.subText}>{t("parcel_send_page.subtext")}</p>

      <div className={styles.bottomWidget}>
        <div onClick={handleNavigateToSelectDateRangePage} className={styles.widgetItem}>
          <span>{t("parcel_send_page.dispatch_period")}</span>
          {dateRange && dateRange[0]?.startDate && dateRange[0]?.endDate && (
            <span className={styles.destinationInfo}>
              {formatDate(dateRange[0].startDate)}-{formatDate(dateRange[0].endDate)}
            </span>
          )}
          <ArrowRightIcon />
        </div>
        <div onClick={handleNavigateToSelectParcelSizePage} className={styles.widgetItem}>
          <span>{t("parcel_send_page.parcel_size")}</span>
          {parcelSize && <span className={styles.destinationInfo}>{parcelSize}</span>}
          <ArrowRightIcon />
        </div>
      </div>

      <Button
        disabled={!(from.title && to.title && dateRange && parcelSize)}
        text={t("parcel_send_page.search_button")}
        size={"large"}
        color={"fill"}
        onClick={handleNavigateToSearchResultsPage}
      />

      {recentSearchResults.length > 0 && (
        <div className={styles.recentSearchBlock}>
          <h2 className={styles.recent}>{t("recent")}</h2>
          <div className={styles.searchItems}>
            {recentSearchResults.slice(0, 5).map((result) => (
              <div
                onClick={() =>
                  handleNavigateToSearchResultsPageFromRecent(result.from, result.to, result.parcelSize, {
                    startDate: new Date(JSON.parse(result?.startDate)),
                    endDate: new Date(JSON.parse(result?.endDate)),
                  })
                }
                className={styles.resultItem}
              >
                <div className={styles.resultInfo}>
                  <div className={styles.resultInfoText}>
                    <p>
                      <span>{result.from.title || result.from}</span> - <span>{result.to.title || result.to}</span>
                    </p>
                    <p className={styles.infoText}>
                      {formatFullDate(new Date(JSON.parse(result?.startDate)))} -{" "}
                      {formatFullDate(new Date(JSON.parse(result?.endDate)))}
                    </p>
                  </div>
                  <div className={styles.ratingWrapper}>
                    <span>{result.parcelSize}</span>
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Navigation />
    </div>
  );
};

export default ParcelSendPage;
