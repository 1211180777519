import React, { FC, useEffect, useState } from "react";

import { ReactComponent as SendIcon } from "../../assets/icons/navigation/sendIcon.svg";
import { ReactComponent as SendActiveIcon } from "../../assets/icons/navigation/sendActiveIcon.svg";
import { ReactComponent as DeliverIcon } from "../../assets/icons/navigation/deliverIcon.svg";
import { ReactComponent as DeliverActiveIcon } from "../../assets/icons/navigation/deliverActiveIcon.svg";
import { ReactComponent as TrackingIcon } from "../../assets/icons/navigation/trackingIcon.svg";
import { ReactComponent as TrackingActiveIcon } from "../../assets/icons/navigation/trackingActiveIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/navigation/profileIcon.svg";
import { ReactComponent as ProfileActiveIcon } from "../../assets/icons/navigation/profileActiveIcon.svg";

import styles from "./navigation.module.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../const/routes";

enum TABS_VALUES {
  send = 1,
  deliver = 2,
  tracking = 3,
  profile = 4,
}

const Navigation: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    location.pathname === ROUTE_PATHS.PARCEL_SEND
      ? TABS_VALUES.send
      : location.pathname === ROUTE_PATHS.TRACKING
      ? TABS_VALUES.tracking
      : location.pathname === ROUTE_PATHS.PARCEL_DELIVER
      ? TABS_VALUES.deliver
      : TABS_VALUES.profile
  );

  const handleTabChange = (value: TABS_VALUES) => {
    switch (value) {
      case TABS_VALUES.send:
        navigate(
          `${ROUTE_PATHS.PARCEL_SEND}?hashed-tg-id=${searchParams.get("hashed-tg-id")}&tg-id=${searchParams.get(
            "tg-id"
          )}`
        );

        break;
      case TABS_VALUES.profile:
        navigate(
          `${ROUTE_PATHS.PROFILE}?hashed-tg-id=${searchParams.get("hashed-tg-id")}&tg-id=${searchParams.get("tg-id")}`
        );

        break;
      case TABS_VALUES.tracking:
        navigate(
          `${ROUTE_PATHS.TRACKING}?hashed-tg-id=${searchParams.get("hashed-tg-id")}&tg-id=${searchParams.get("tg-id")}`
        );

        break;
      case TABS_VALUES.deliver:
        navigate(
          `${ROUTE_PATHS.PARCEL_DELIVER}?hashed-tg-id=${searchParams.get("hashed-tg-id")}&tg-id=${searchParams.get(
            "tg-id"
          )}`
        );
        break;
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case ROUTE_PATHS.PROFILE:
        setActiveTab(TABS_VALUES.profile);
        break;
      case ROUTE_PATHS.PARCEL_SEND:
        setActiveTab(TABS_VALUES.send);
        break;
      case ROUTE_PATHS.TRACKING:
        setActiveTab(TABS_VALUES.tracking);
        break;
      case ROUTE_PATHS.PARCEL_DELIVER:
        setActiveTab(TABS_VALUES.deliver);
        break;
    }
  }, [location]);
  return (
    <nav className={styles.navigation}>
      <button onClick={() => handleTabChange(TABS_VALUES.send)} className={styles.tabItem}>
        {activeTab === TABS_VALUES.send ? <SendActiveIcon /> : <SendIcon />}
        <p className={activeTab === TABS_VALUES.send ? styles.active : ""}>{t("navigation.send")}</p>
      </button>
      <button onClick={() => handleTabChange(TABS_VALUES.deliver)} className={styles.tabItem}>
        {activeTab === TABS_VALUES.deliver ? <DeliverActiveIcon /> : <DeliverIcon />}
        <p className={activeTab === TABS_VALUES.deliver ? styles.active : ""}>{t("navigation.deliver")}</p>
      </button>
      <button onClick={() => handleTabChange(TABS_VALUES.tracking)} className={styles.tabItem}>
        {activeTab === TABS_VALUES.tracking ? <TrackingActiveIcon /> : <TrackingIcon />}
        <p className={activeTab === TABS_VALUES.tracking ? styles.active : ""}>{t("navigation.tracking")}</p>
      </button>
      <button onClick={() => handleTabChange(TABS_VALUES.profile)} className={styles.tabItem}>
        {activeTab === TABS_VALUES.profile ? <ProfileActiveIcon /> : <ProfileIcon />}
        <p className={activeTab === TABS_VALUES.profile ? styles.active : ""}>{t("navigation.profile")}</p>
      </button>
    </nav>
  );
};

export default Navigation;
