import React, { useEffect, useRef, useState } from "react";
import { useSendParcel } from "../../context/SendParcelContext";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ReactComponent as NotificationIcon } from "../../assets/icons/notificationIcon.svg";
import { ReactComponent as SendParcelIcon } from "../../assets/icons/sendParcelIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrowRight.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/starSmall.svg";

import styles from "./ParcelSendSearchResultsPage.module.css";
import { Button } from "../../components/Button/Button";
import {
  formatDate,
  formatFullDate,
  transformDate,
} from "../../utils/dateUtil";
import { MakeNotificationDto } from "../../service/parcel/parcel";
import Avatar, { AvatarSizes } from "../../components/Avatar/Avatar";
import { EToast, useToast } from "../../context/ToastContext";
import { useTranslation } from "react-i18next";
import { useRecentSearch } from "../../context/RecentSearchContext";
import { getHashedTgIdFromLocalStorage } from "../../utils/storeActions";
import { ROUTE_PATHS } from "../../const/routes";
import { observer } from "mobx-react-lite";
import globalRequestStore from "../../store";

const ParcelSendSearchResultsPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const tgId = getHashedTgIdFromLocalStorage();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { postRecentSearchResult, handleGetRecentSearch } = useRecentSearch();
  const {
    from,
    to,
    parcelSize,
    dateRange,
    handleSearchForParcelSend,
    handleMakeNotification,
    handleGetNearestDates,
    handleReset,
    handleFromChange,
    handleToChange,
  } = useSendParcel();

  const [results, setResults] = useState([]);
  const [nearestDates, setNearestDates] = useState([]);
  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);
  const titleRef = useRef<any>(null);

  const fetchResults = async () => {
    setLoading(true);

    if (tgId && from && to && parcelSize && dateRange) {
      const obj = {
        from: from.placeId,
        to: to.placeId,
        parcelSize,
        startDate: formatFullDate(dateRange[0]?.startDate),
        endDate: formatFullDate(dateRange[0]?.endDate),
      };

      const data = await handleSearchForParcelSend(tgId as string, obj);

      const nearestTop = await handleGetNearestDates(
        tgId as string,
        obj,
        "top"
      );
      const nearestBottom = await handleGetNearestDates(
        tgId as string,
        obj,
        "bottom"
      );
      const nearest = [
        ...nearestTop?.results,
        ...nearestBottom?.results,
      ] as any;
      if (nearest) {
        setNearestDates(nearest);
      }

      if (data) {
        setResults(data.results);
      }

      const recentResults =
        (await handleGetRecentSearch(tgId as string, "send")) || [];
      await postRecentSearchResult(
        tgId as string,
        [
          {
            from,
            to,
            parcelSize,
            startDate: JSON.stringify(dateRange[0]?.startDate),
            endDate: JSON.stringify(dateRange[0]?.endDate),
          },
          ...recentResults.slice(0, 4),
        ],
        "send"
      );
    }
    setLoading(false);
  };

  const handleMakeNotificationRequest = async () => {
    setLoading(true);
    try {
      if (tgId) {
        const postRequestData: MakeNotificationDto = {
          departure_point: from.placeId,
          destination_point: to.placeId,
          dispatch_period_end: formatFullDate(dateRange[0]?.endDate),
          dispatch_period_start: formatFullDate(dateRange[0]?.startDate),
          user: tgId as string,
          size: parcelSize,
        };

        const data = await handleMakeNotification(
          tgId as string,
          postRequestData
        );

        if (data) {
          navigate(`${ROUTE_PATHS.PARCEL_SEND}?hashed-tg-id=${tgId as string}`);
          console.log(
            "navigate(`${ROUTE_PATHS.PARCEL_SEND}?hashed-tg-id=${tgId as string}`)"
          );
          showToast(
            t("parcel_send_search_results_page.popup_text"),
            EToast.ACCEPT
          );
        }
        handleReset();
      }
    } catch (err) {}
    setLoading(false);
  };

  const handleOpenResultPage = (result) => {
    navigate(
      `${ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULTS}/${result.courier.id}?tripId=${
        result.id
      }&price=${result.sizes[`${parcelSize}`]}&size=${parcelSize}&details=${
        result.additional_info || ""
      }&hashed-tg-id=${tgId}&from=${JSON.stringify(from)}&to=${JSON.stringify(
        to
      )}&destination=${
        result.departure_point + " - " + result.destination_point
      }&dates=${
        transformDate(result.dispatch_period_start) +
        " - " +
        transformDate(result.dispatch_period_end)
      }`
    );
    console.log("handleOpenResultPage");
    // handleFromChange(result.departure_point)
    // handleFromChange(result.destination_point)
    //OLD CODE
    // navigate(`${ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULTS}/${result.courier.id}?tripId=${result.id}&price=${result.sizes[`${parcelSize}`]}&size=${parcelSize}&details=${result.additional_info || ''}&hashed-tg-id=${tgId}&from=${JSON.stringify(from)}&to=${JSON.stringify(to)}&destination=${from.title + ' - ' + to.title}&dates=${transformDate(result.dispatch_period_start) + ' - ' + transformDate(result.dispatch_period_end)}`)
  };

  useEffect(() => {
    if (tgId) {
      fetchResults();
    }
  }, [searchParams]);

  useEffect(() => {
    if (fromRef.current && toRef.current && titleRef.current) {
      const fromWidth = fromRef.current.offsetWidth;
      const toWidth = toRef.current.offsetWidth;
      const titleWidth = fromWidth + toWidth + 20;

      const maxWidth = titleRef.current.offsetWidth;

      titleRef.current.style.flexDirection =
        titleWidth > maxWidth ? "column" : "row";
    }
  }, [from, to]);

  return (
    <div className={styles.pageWrapper}>
      {from && to && dateRange && (
        <div className={styles.topWidget}>
          <SendParcelIcon />
          <h1 className={styles.title} ref={titleRef}>
            <p ref={fromRef}>{from.title}</p>
            <span>-</span>
            <p ref={toRef}>{to.title}</p>
          </h1>
          <p className={styles.subTitle}>
            {formatDate(dateRange[0]?.startDate)} -{" "}
            {formatDate(dateRange[0]?.endDate)}
          </p>
          <span className={styles.parcelSize}>{parcelSize}</span>
        </div>
      )}

      <div className={styles.resultsWrapper}>
        <h2 className={styles.subtitle}>
          {t("parcel_send_search_results_page.results")}
        </h2>
        <div className={styles.resultsList}>
          {results.length
            ? results.map((result: any) => (
                <div
                  onClick={() => handleOpenResultPage(result)}
                  className={styles.resultItem}
                >
                  <Avatar
                    profileName={result?.courier?.name}
                    avatarUrl={result?.courier?.photo_link}
                    size={AvatarSizes.s}
                  />
                  <div className={styles.resultInfo}>
                    <div className={styles.name}>
                      <p className={styles.courierName}>
                        {result?.courier?.name}
                      </p>
                      {result?.courier?.rating && (
                        <span>
                          <StarIcon />
                          {result?.courier?.rating}
                        </span>
                      )}
                    </div>

                    <p className={styles.infoText}>
                      <span>{result.departure_point}</span> -{" "}
                      <span>{result.destination_point}</span>
                    </p>
                    <p className={styles.infoText}>
                      {transformDate(result.dispatch_period_start)} -{" "}
                      {transformDate(result.dispatch_period_end)}
                    </p>
                    <div className={styles.ratingWrapper}>
                      <span>{result.sizes[`${parcelSize}`]}$</span>
                      <ArrowRightIcon />
                    </div>
                  </div>
                </div>
              ))
            : !globalRequestStore.isLoading && (
                <div className={styles.noResults}>
                  <NotificationIcon />
                  <div className={styles.subtext}>
                    <p>
                      {t("parcel_send_search_results_page.no_results.title")}
                    </p>
                    <p>
                      {t(
                        "parcel_send_search_results_page.no_results.description"
                      )}
                    </p>
                  </div>
                </div>
              )}
        </div>
      </div>

      {!!nearestDates.length && (
        <div className={styles.resultsWrapper}>
          <div className={styles.subtitle}>
            {t("parcel_send_search_results_page.nearest_dates")}
          </div>
          <div className={styles.resultsList}>
            {nearestDates.map((result: any) => {
              return (
                <div
                  onClick={() => handleOpenResultPage(result)}
                  className={styles.resultItem}
                >
                  <Avatar
                    profileName={result?.courier?.name}
                    avatarUrl={result?.courier?.photo_link}
                    size={AvatarSizes.s}
                  />
                  <div className={styles.resultInfo}>
                    <p className={styles.courierName}>
                      {result?.courier?.name}
                      {result?.courier?.rating && (
                        <span>
                          <StarIcon />
                          {result?.courier?.rating}
                        </span>
                      )}
                    </p>
                    <p className={styles.infoText}>
                      <span>{result.departure_point}</span> -{" "}
                      <span>{result.destination_point}</span>
                    </p>
                    <p className={styles.infoText}>
                      {transformDate(result.dispatch_period_start)} -{" "}
                      {transformDate(result.dispatch_period_end)}
                    </p>
                    <div className={styles.ratingWrapper}>
                      <span>{result.sizes[`${parcelSize}`]}$</span>
                      <ArrowRightIcon />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!results.length && (
        <Button
          disabled={loading}
          onClick={handleMakeNotificationRequest}
          color={"fill"}
          text={t("parcel_send_search_results_page.make_notification_button")}
          size={"large"}
        />
      )}
    </div>
  );
});

export default ParcelSendSearchResultsPage;
